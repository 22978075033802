import portfolioTile from  '../static/project-tiles/portfolio-site-tile.png';
import pronounsTile from  '../static/project-tiles/pronoun-trainer-tile.png';

const projects = [
  {
    name: 'alex-barron-portfolio',
    title: 'This website',
    description: 'alexbarron.xyz is a ReactJS app leveraging Material UI components.',
    url: 'https://alexbarron.xyz',
    repoUrl: 'https://bitbucket.org/abarron87/alexbarron_developer',
    image: portfolioTile,
    color: '#ff5722',
    keywords: ['reactrouterdom', 'nestedroutes', 'dynamiccontent', 'languagetoggle']
  },
  {
    name: 'french-pronoun-trainer',
    title: 'French Pronoun Trainer',
    description: 'A tool for French learners to build sentences.',
    url: 'https://french-pronoun-trainer-react.alexbarron.xyz',
    repoUrl: 'https://bitbucket.org/abarron87/french-sentence-tool-react',
    image: pronounsTile,
    color: '#3f51b5',
    keywords: ['reactjs', 'statelessfunctionalcomponents', 'compositionoverinheritance', 'materialui']
  }
];

export default projects;