import React, { Component } from 'react';
import { Link as RouterLink, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import './App.scss';
import About from './components/About';
import Work from './components/Work';
import NotFound from './components/NotFound';
import { AppBar, Typography, Tabs, Tab, BottomNavigation, BottomNavigationAction, Button, Link, IconButton, Menu, MenuItem } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cv from './static/CV.pdf';
import cvFr from './static/CV-fr.pdf';
import frFlag from './static/france-flag.png';
import ukFlag from './static/uk-flag.png';
import emailAddress, { youtube, linkedin } from './data/links';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      topBarPosition: 'sticky',
      bottomNavValue: null,
      language: 'en',
      languageSwitcherOpen: false,
      anchorEl: null
    };

    // this.handleNavChange = this.handleNavChange.bind(this);
    this.handleRouteToPageWithSubBar = this.handleRouteToPageWithSubBar.bind(this);
    this.handleLanguageButtonClick = this.handleLanguageButtonClick.bind(this);
    this.handleLanguageSwitcherClose = this.handleLanguageSwitcherClose.bind(this);
  }

  /*
    Before mounting, create listener than executes on route change.
    Set starting active tab. Allows page to be reloading anywhere on the site
    and the tab whose href corresponds to the current path will be activated.
  */
  componentWillMount() {
    const { history } = this.props;
    const currentPath = history.location.pathname;
    const initialLang = this.calculateLanguage(currentPath);
    
    if(initialLang !== this.state.language) {
      this.setLanguage(initialLang);
    }

    this.setActiveTab(this.calculateActiveTab(history.location.pathname));

    this.unlisten = history.listen((location) => {
      const newPath = location.pathname;
      const currentUrlLang = this.calculateLanguage(newPath);

      this.setActiveTab(this.calculateActiveTab(newPath));

      if (currentUrlLang !== this.state.language) {
        this.setLanguage(currentUrlLang);
      }
    });
  }

  /*
    On unmount i.e. when closing the app because this component is on all pages, cancel the listener.
  */
  componentWillUnmount() {
    this.unlisten();
  }

  handleRouteToPageWithSubBar(hasSubBar) {
    this.setState({ topBarPosition: hasSubBar ? 'static' : 'sticky' });
  }

  handleLanguageButtonClick(e) {
    this.setState({ languageSwitcherOpen: !this.state.languageSwitcherOpen, anchorEl: document.getElementById('language-switcher') });
  }

  handleLanguageSwitcherClose() {
    this.setState({ languageSwitcherOpen: false });
  }

  calculateLanguage(path) {
    const re = /(en|fr)/;
    const lang = path.split('/')[1].match(re);

    if (lang && lang[1] === 'fr') {
      return 'fr';
    } else {
      return 'en';
    }
  }

  /*
    Using current path from the history object, guage which top level page we are within.
    return index of corresponding tab. this will be set as the active tab.
  */
  calculateActiveTab(path) {
    let tabValue;

    switch(path.split('/')[2]) {
      case '':
        tabValue = 0;
        break;
      case 'work':
        tabValue = 1;
        break;
      default:
        tabValue = 0;
        break;
    }

    return tabValue;
  }

  generateUrl(lang) {
    const { history } = this.props;
    const path = history.location.pathname;
    let pathParts = path.split('/');
    pathParts.splice(0, 1); // remove the empty string from the start. if url is / then array will be ['','']. if it's /fr, then it'll be ['','fr']
    const langPart = pathParts[0];

    if (langPart !== lang) {
      pathParts.splice(0, 1, lang); //add fr to the start. at root this'll leave ['fr', ''] but we don't care.
      return `/${pathParts.join('/')}`; // forward slash at the start is important to create an absolute path.
    } else {
      return path;
    }
  }

  setActiveTab(tabValue) {
    this.setState({ tabValue: tabValue });
  }

  setLanguage(lang) {
    this.setState({ language: lang });
  }

  render() {
    const { tabValue, topBarPosition, language, languageSwitcherOpen, anchorEl } = this.state;
    const { history } = this.props;

    return (
      <div className="App">
        <div id="top">
          <AppBar position={topBarPosition} id="top-app-bar" className="app-bar">
            <div>
              <Typography variant="h6" color="inherit" align="left" id="site-name">
                <RouterLink to={`/${language}/`}>Alex Barron</RouterLink>
              </Typography>
              <div id="top-app-bar-right">
                {/* onChange prop not used because the change is handled by the withRouter HOC history listener. */}
                <Tabs value={tabValue} id="top-nav-tabs">
                  <Tab label={'Home'} component={RouterLink} to={`/${language}/`} />
                  <Tab label={'Work'} component={RouterLink} to={`/${language}/work`} />
                  {/* <Tab label={<div><p>Y En</p><small>As Object Pronouns</small></div>} component={RouterLink} to="/adverbial" /> */}
                </Tabs>
                <div id="language-switcher">
                  <Button aria-label="Choose language" aria-haspopup="true" onClick={this.handleLanguageButtonClick}>
                    <img src={ukFlag} />
                    <img src={frFlag} />
                  </Button>
                  <Menu open={languageSwitcherOpen} anchorEl={anchorEl} onClose={this.handleLanguageSwitcherClose} id="language-list">
                    <MenuItem onClick={this.handleLanguageSwitcherClose} className={language === 'en' ? 'selected-language' : ''}>
                      <RouterLink to={this.generateUrl('en')} className="language-item"><img src={ukFlag} className="language-image" /> <Typography component="span" className="language-label">English</Typography></RouterLink>
                    </MenuItem>
                    <MenuItem onClick={this.handleLanguageSwitcherClose} className={language === 'fr' ? 'selected-language' : ''}>
                      <RouterLink to={this.generateUrl('fr')} className="language-item"><img src={frFlag} className="language-image" /> <Typography component="span" className="language-label">Français</Typography></RouterLink>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          </AppBar>
          <main>
            <Switch>
              <Redirect exact from="/" to="/en" />
              <Route exact path="/:lang" render={(props) => <About {...props} onRouteLoad={this.handleRouteToPageWithSubBar} lang={language} />} />
              <Route path="/:lang/work" render={(props) => <Work {...props} onRouteLoad={this.handleRouteToPageWithSubBar} lang={language} />} />
              <Route path="/*" component={NotFound} />
            </Switch>
          </main>
        </div>
        <footer id="bottom">
          <div>
            <div className="button-list">
              <Button className="footer-item linkedin">
                <Link href={linkedin} target="_blank">
                  <Typography component="span" className="footer-item-icon"><FontAwesomeIcon icon={['fab', 'linkedin']} /></Typography>
                  <Typography component="span" className="footer-item-label">LinkedIn</Typography>
                </Link>
              </Button>
              <Button className="footer-item youtube">
                <Link href={youtube} target="_blank">
                  <Typography component="span" className="footer-item-icon"><FontAwesomeIcon icon={['fab', 'youtube']} /></Typography>
                  <Typography component="span" className="footer-item-label">YouTube</Typography>
                </Link>
              </Button>
              <Button className="footer-item cv"> 
                <Link href={language === 'en' ? cv : cvFr } target="_blank">
                  <Typography component="span" className="footer-item-icon"><FontAwesomeIcon icon={['far', 'file']} /></Typography>
                  <Typography component="span" className="footer-item-label">CV/Résumé</Typography>
                </Link>
              </Button>
              <Button className="footer-item contact"> 
                <Link href={emailAddress} target="_blank">
                  <Typography component="span" className="footer-item-icon"><FontAwesomeIcon icon={['fas', 'at']} /></Typography>
                  <Typography component="span" className="footer-item-label">Email me</Typography>
                </Link>
              </Button>
            </div>
            <div className="small-print">
              <Typography component="small">&copy; Copyright Alex Barron 2019</Typography>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(App);
