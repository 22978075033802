import React, { Fragment } from 'react';
import { Typography, GridListTile, GridListTileBar, GridList, Link, Button } from '@material-ui/core';
import projects from '../data/projects';
import l10n from '../data/l10n';

const Work = (props) => {
  const keys = l10n.work;
  const { lang } = props;

  return (
    <div className="container" id="work">
      <header className="page-header">
        <Typography variant="h2" gutterBottom>{keys.topHeading[lang]}</Typography>
        <Typography variant="subtitle1">{keys.subHeading1[lang]}</Typography>
        <Typography variant="subtitle2">{keys.subHeading2[lang]}</Typography>
      </header>
      <div className="main-content-container">
        <GridList cols={1} id="project-tiles">
          {projects.map(p => (
            <GridListTile key={p.name} className="project-tile" style={{ backgroundImage: 'url(' + p.image + ')', backgroundColor: p.color }}>
              <GridListTileBar title={p.title} className="info-bar" />
              <div className="tile-overlay">
                <div className="tile-overlay-contents">
                  <Typography variant="h4">{p.title}</Typography>
                  <Typography variant="h5">{p.description}</Typography>
                  <Typography variant="caption" className="keywords">
                    {p.keywords.map((kw, index) => (
                      <Fragment key={index}>#{kw}&nbsp;</Fragment>
                    ))}
                  </Typography>
                  <Typography component="div" className="button-container">
                    <Button color="secondary" className="primary-button"><Link href={p.url}>Click to visit</Link></Button>
                    <Button color="secondary" variant="outlined"><Link href={p.repoUrl}>View source</Link></Button>
                  </Typography>                  
                </div>
              </div>
            </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  );
}

export default Work;