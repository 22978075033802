import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faYoutube, faLinkedin, faAtlassian, faReact, faAngular, faJs, faSass, faLess, faGrunt, faNodeJs, faGithub, faCss3Alt } from '@fortawesome/free-brands-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faFutbol, faComments, faHiking, faMountain, faStreetView, faExternalLinkAlt, faAt, faUsers, faFileInvoice, faUserClock, faGlobeEurope, faChild, faCodeBranch } from '@fortawesome/free-solid-svg-icons';

library.add([
  faFutbol, faComments, faHiking, faMountain, faStreetView, faYoutube, faExternalLinkAlt, 
  faFile, faLinkedin, faAt, faUsers, faFileInvoice, faUserClock, faGlobeEurope, faChild, faCodeBranch,
  faAtlassian, faReact, faAngular, faJs, faSass, faLess, faGrunt, faNodeJs, faGithub, faCss3Alt ]);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fafafa'
    },
    secondary: {
      main: '#ff5722'
    }
  },
  typography: {
    useNextVariants: true,
    // fontSize: 16
  }
});

ReactDOM.render(
  (
    <MuiThemeProvider theme={theme}>
      <HashRouter>
        <App />
      </HashRouter>
    </MuiThemeProvider>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
