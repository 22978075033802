import React from 'react';
import { Typography, Tabs, Tab, AppBar } from '@material-ui/core';
import TabContent from './TabContent';
import aboutPage from '../data/aboutPage';

class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tabValue: 0 };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentWillMount() {
    this.props.onRouteLoad(true);
  }

  handleTabChange(e, value) {
    this.setState({ tabValue: value });
  }

  render() {
    const { tabValue } = this.state;
    const { lang } = this.props;

    return (
      <div className="about-component component-content-container">
        <AppBar position="sticky" color="secondary" className="page-app-bar">
          <div className="xcontainer">
            <Tabs value={tabValue} onChange={this.handleTabChange} indicatorColor="primary" variant="fullWidth">
              <Tab label="Professional" />
              <Tab label="Personal" />
            </Tabs>
          </div>
        </AppBar>
        <div className="container">
          <div className="main-content-container">
            {tabValue === 0 && <TabContent>{aboutPage('pro', lang)}</TabContent>}
            {tabValue === 1 && <TabContent>{aboutPage('personal', lang)}</TabContent>}
          </div>
        </div>
      </div>
    );
  }
}

export default About;