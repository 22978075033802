import React, { Fragment } from 'react';
import cv from '../static/CV.pdf';
import cvFr from '../static/CV-fr.pdf';
// import cvFr from '../static/CVFr.pdf';
import { Typography, Link } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { youtube } from './links';

const l10n = {
  about: {
    pro: {
      topHeading: {
        en: <Fragment>I love to bring ideas to life in the browser. <Typography inline variant="button" component="small"><Link href={cv} target="_blank" color="secondary"><FontAwesomeIcon icon="external-link-alt"  />&nbsp;Read my CV here</Link>.</Typography></Fragment>,
        fr: <Fragment>J'adore donner vie aux idées dans le navigateur. <Typography inline variant="button" component="small"><Link href={cvFr} target="_blank" color="secondary"><FontAwesomeIcon icon="external-link-alt"  />&nbsp;Lire mon CV ici</Link> (<Link href={cv} target="_blank" color="secondary" id="en-cv-link">Ou en anglais</Link>).</Typography></Fragment>
      },
      tldr: {
        label: { en: 'Too long, don\'t read. (i.e. the short version.)', fr: 'Trop long, ne pas lire. (de l\'anglais: too long, don\'t read) (le résumé.)' },
        items: [
          { id: 'remote', icon: 'globe-europe', text: { en: 'Remote specialist.', fr: 'Spécialiste en télétravail.' } },
          { id: 'experience', icon: 'user-clock', text: { en: 'Developer since 2010.', fr: 'Développeur depuis 2010.' } },
          { id: 'autonomous', icon: 'child', text: { en: 'I work well alone and in a team.', fr: 'Autonome, mais avec l\'esprit d\'équipe.' } },
          { id: 'best-practices', icon: 'file-invoice', text: { en: 'Follower of best practices.', fr: 'Abonné aux bonnes pratiques.' } }
        ]
      },
      paragraph1: {
        en: 'Ever since a course at University introduced me to the world of HTML, JavaScript, CSS, and PHP, I’ve thoroughly enjoyed the feeling of making things work before our eyes on screen.',
        fr: <Fragment>Depuis un cours à l'université qui m'a fait découvrir le monde de l'HTML, du JavaScript, du CSS, et du PHP, j'aime faire fonctionner les nouvelles fonctionnalités sous vos yeux.</Fragment>
      },
      paragraph2: {
        en: <Fragment>
              10 years later, I’m a highly competent developer, specialising in the Frontend. I’m from London but live in Montpellier, France to follow my passion for foreign language. 
              Living in France means I am surrounded by new sounds and words all the time; something I love. 
              I want to find a company which shares my passion for learning and growing. In fact, I love travelling and am always striving to find the best balance between staying at home and 
              leaving the nest; working as I go.
            </Fragment>,
        fr: <Fragment>
              Dix ans plus tard, je suis devenu un développeur très compétent, qui se spécilise dans le front-end. Je viens de Londres mais vis à Montpellier pour poursuivre ma passion pour les langues étrangères.
              En vivant en France, je vois et entends des nouveaux mots et expressions tous les jours, ce qui me plaît beaucoup.
              Je souhaite trouver une entreprise qui partage ma passion d'apprendre et de s'améliorer. De plus, j'adore voyager et j'essaie toujours de trouver le meilleur équilibre entre rester chez moi 
              et partir à un pays étranger. Je travaille souvent depuis le lieux où j'ai atterris.
            </Fragment>
      },
      subheading1: {
        en: <Fragment>Remote specialist</Fragment>,
        fr: <Fragment>Un spécialiste en missions à distance</Fragment>
      },
      paragraph3: {
        en: <Fragment>
              I’ve been working remotely since 2015, and as a result am extremely used to being part of a distributed team, meeting on a daily basis in the the regular stand-up meetings, and 
              communicating with other team members over Slack, Google Hangouts, or whatever it may be. I’ve also become a huge advocate of location-independence and remote-working, and I 
              believe my productivity thrives because of it.
            </Fragment>,
        fr: <Fragment>
              Je travaille en remote depuis 2015, et grâce à mon expérience je suis habitué à faire partie d'une équipe en télétravail, qui se retrouve quotidiennement pour un meeting,
              et à devoir communiquer avec mes collègues via Slack, Google Hangouts, ou tout autre logiciel de communication. De plus, je suis devenu un grand défenseur du style de vie où les lieux
              d'où je travaille a peu d'importance. Un style de vie qui s'appelle «location independent». Grâce à cela, je crois que je suis énormément plus productif.
            </Fragment>
      },
      paragraph4: {
        en: <Fragment>
              Most of the time, I work from my home or from co-working spaces in Montpellier, France, but find it beneficial to go and visit a co-living space somewhere in the world to get my 
              travel fix and increase my productivity at the same time. So far, I've stayed at Sun &amp; Co in Spain, CoCo Hub in Malta, and Kohub in Thailand.
            </Fragment>,
        fr: <Fragment>
              La plupart du temps, je travaille de la maison, ou des espaces de co-working à Montpellier. Une ou deux fois par an, je pars à l'étranger à un espace co-living pour vivre ma dose
              de voyage habituel, ce qui, en même temps, augmente ma productivité. Jusqu'au présent, je suis allé à Sun &amp; Co en Espagne, à CoCoHub à Malte, et à Kohub en Thaïlande.
            </Fragment>
      },
      subheading2: {
        en: <Fragment>Autonomous but team-mates are essential</Fragment>,
        fr: <Fragment>Autonome, mais avoir des co-équipiers essentiel</Fragment>
      },
      paragraph5: {
        en: <Fragment>
              Being fully capable of taking ownership of features is one of my strengths. Having team-mates and peers with whom to talk about a problem is invaluable to the reflection and conception
              process, as well as when solving unexpected problems. Simply being able to vocalise your thoughts brings clarity and this is one reason why it's essential to be part of a team (incredibly
              good for sanity too!).
            </Fragment>,
        fr: <Fragment>
              Un de mes points forts est d'être capable de prendre en charge les projets. Avoir des co-équipiers avec qui parler d'une problématique est précieux pour réfléchir à la conception de solutions,
              ainsi que résoudre un problème imprévu. Être capable de s'éxprimer à haute voix clarifie la situation et c'est une raison pour laquelle il est primordial de faire partie
              d'une équipe (c'est bon pour la santé aussi !).
            </Fragment>
      },
      subheading3: {
        en: <Fragment>Tech experience</Fragment>,
        fr: <Fragment>Expérience en informatique</Fragment>
      },
      paragraph6: {
        en: <Fragment>
              I've a wealth of professional experience with AngularJS from my latest contract working to build a DAM/CMS solution.
              Here's a list of some of the technologies across the stack that I've worked with over the course of my career (it really wouldn't be possible to list all&hellip;) and 
              that I would enjoy working with:
            </Fragment>,
        fr: <Fragment>
              J'ai une foule d'expériences professionelles avec AngularJS depuis mon dernier contrat. Je travaillais sur une plateforme CMS.
              Ci-dessous se trouve une liste des technologies avec lesquelles j'ai travaillées au cours de ma carrière
              (ce n'est vraiment pas possible de tout inclure) et avec lesquelles j'aimerais bien retravailler dans le futur :
            </Fragment>
      },
      chips1: [
        { id: 'angularjs',  en: 'AngularJS', fr: 'AngularJS', important: true },
        { id: 'jquery', en: 'jQuery', fr: 'jQuery', important: true },
        { id: 'vanillajs', en: 'Vanilla JS (ES5 &amp; 6)', fr: 'JS pur (ES5 &amp; 6)', icon: ['fab', 'js'], important: true },
        { id: 'angular5', en: 'Angular 5', fr: 'Angular 5', icon: ['fab', 'angular'], important: true },
        { id: 'typescript', en: 'Typescript (with Angular)', fr: 'Typescript (avec Angular)', important: true },
        { id: 'reactjs', en: 'ReactJS with Material UI', fr: 'ReactJS avec Material UI', icon: ['fab', 'react'], important: true },
        { id: 'sass', en: 'SASS', fr: 'SASS', icon: ['fab', 'sass'], important: true },
        { id: 'less', en: 'LESS', fr: 'LESS', icon: ['fab', 'less'], important: true },
        { id: 'gruntjs', en: 'GruntJS', fr: 'GruntJS', icon: ['fab', 'grunt'], important: true },
        { id: 'xslt', en: 'XSLT (Love it)', fr: 'XSLT (J\'adore)', important: true },
        { id: 'git', en: 'GIT with GitFlow', fr: 'GIT avec GitFlow', icon: 'code-branch' },
        { id: 'atlassian', en: 'Atlassian Suite: Bitbucket, JIRA, Stash, SourceTree, Confluence', fr: 'Atlassian Suite: Bitbucket, JIRA, Stash, SourceTree, Confluence', icon: ['fab', 'atlassian'] },
        { id: 'firebase', en: 'Firebase', fr: 'Firebase' },
        { id: 'nodejs', en: 'NodeJS with Express', fr: 'NodeJS avec Express', icon: ['fab', 'node-js'] },
        { id: 'jekyl', en: 'Jekyl for GitHub Pages', fr: 'Jekyl pour GitHub Pages', icon: ['fab', 'github'] }
      ],
      paragraph7: {
        en: <Fragment>
              Much of my time in a professional capacity has been spent in the Digital Asset Management space, and I’m keen for a new challenge. One that allows me to work with the latest 
              technologies, working for a company that invests in my learning, and understands the importance of work/life balance.
            </Fragment>,
        fr: <Fragment>
              Je cherche un nouveau challenge qui me permette de travailler avec les dernières technologies, et de travailler pour une entreprise qui investit dans mon apprentissage, et qui
              comprenne l'importance de l'équilibre vie-travail.
            </Fragment>
      },
      paragraph8: {
        en: <Fragment>Things I'm improving in my spare time:</Fragment>,
        fr: <Fragment>Dans mon temps libre, j'améliore mes compétences en :</Fragment>
      },
      chips2: [
        { id: 'reactjs', en: 'ReactJS', fr: 'ReactJS', icon: ['fab', 'react'], important: true },
        { id: 'flexbox', en: 'Flexbox', fr: 'Flexbox', icon: ['fab', 'css3-alt'], important: true },
        { id: 'angular', en: 'Angular', fr: 'Angular', icon: ['fab', 'angular'], important: true }
      ],
      subheading4: {
        en: <Fragment>Looking for more UX/UI experience</Fragment>,
        fr: <Fragment>A la recherche de plus d'expérience en UX/UI</Fragment>
      },
      paragraph9: {
        en: <Fragment>
              As a developer with a keen eye for a good aesthetic that makes sense, gaining more professional experience in the UX/UI space is something I see as incredibly important in my future.
              Following best practices is something I always strive to do, and knowing “why” I’m doing something a certain way is incredibly important to me. It helps me learn and therefore 
              often makes the next challenge easier. Working closely with designers and UX specialists is really enjoyable, and I could listen to someone talk about typography for a whole day.
            </Fragment>,
        fr: <Fragment>
              En tant que developpeur, j'apprécie une bonne esthétique de conception soit aussi logique. J'envisage que l'UX/UI jouera un rôle très important dans mon avenir professionel.
              Je tâche toujours à suivre les meilleures pratiques, et savoir pourquoi je fais quelque chose de façon particulière. Cela m'aide à apprendre et donc rend souvent la tâche suivante plus facile.
              Travailler aux côtés des designeurs et des spécialistes UX est un aspect qui me plaît, et je pourrais écouter un expert parler de la typographie toute la journée.
            </Fragment>
      },
      paragraph10: {
        en: <Fragment>
              I’m known for asking a lot of questions and want to get to the bottom of things. This is probably why I’m so strong when it comes to squashing bugs and eradicating console errors.
            </Fragment>,
        fr: <Fragment>
              On me connaît pour poser beaucoup de questions et je veux aller jusqu'au bout pour résoudre un problème. C'est sans doute la raison pour laquelle je suis si bon pour résoudre des bugs informatiques.
            </Fragment>
      },
      subheading5: {
        en: <Fragment>DRY advocate</Fragment>,
        fr: <Fragment>Défenseur de DRY (Don't Repeat Yourself)</Fragment>
      },
      paragraph11: {
        en: <Fragment>
              Where possible, and where it makes sense, I follow the <em>Don't Repeat Yourself (DRY)</em> philosophy in reducing redundancy in my code. I believe in writing code that will make sense 
              to a future self when I come back to it in a year’s time, or to the next developer: verbose comments, intuitively named functions 
              and variables. With the introduction of build tools for frontend applications, we have no excuse for overly shortening names, rendering them confusing.
            </Fragment>,
        fr: <Fragment>
              Si possible, je m'abonne à la philosophie <em>Don't Repeat Yourself (DRY)</em> en reduisant le redondance dans mon code. Je crois que écrire du code qui aura toujours du sens pour moi mênme après
              un an sans le regarder (par exemple), ou pour le développeur qui le lit après moi : des commentaires verbeux et des fonctions avec des noms intuitifs. Depuis l'introduction des outils qui
              compilent le code des applications Front-end, on n'a plus aucune excuse pour surabréger les noms, et les rendre obscurs.
            </Fragment>
      }
    },
    personal: {
      topHeading: {
        en: <Fragment>When I'm not working&hellip;</Fragment>,
        fr: <Fragment>Quand je ne travaille pas&hellip;</Fragment>
      },
      tldr: {
        label: { en: 'Too long, don\'t read. (i.e. the short version.)', fr: 'Trop long, ne pas lire. (de l\'anglais: too long, don\'t read) (le résumé.)' },
        items: [
          { id: 'language-nerd', icon: 'comments', text: { en: 'Language nerd.', fr: 'Passionné par les langues étrangères.' } },
          { id: 'location-independent', icon: 'street-view', text: { en: 'Location-independent advocate.', fr: 'Défenseur d\'un style de vie indépendant du lieu de résidance.' } },
          { id: 'backpacker', icon: 'hiking', text: { en: 'Backpacker at heart: 25 countries.', fr: 'Backpacker dans l\'âme : 25 pays.' } },
          { id: 'football-fan', icon: 'futbol', text: { en: 'Football fan.', fr: 'Fan de football.' } },
          { id: 'outdoor-enthusiast', icon: 'mountain', text: { en: 'Outdoor enthusiast.', fr: 'Passionné de plein air.' } }
        ]
      },
      subheading1: {
        en: <Fragment>Language nerd</Fragment>,
        fr: <Fragment>Passionné par les langues étrangères</Fragment>
      },
      paragraph1: {
        en: <Fragment>
              I'm hugely passionate about language, and am very proud to say I have taught myself French to an advanced level, and moved to France. Not only that, I feel part of a small, subculture of
              international people in Montpellier, France; something that wouldn't be possible without welcoming a foreign language into my life. I also teach people about everyday French and how
              to learn a language on my <Link href={youtube} target="_blank" color="secondary" className="link-with-icon"><FontAwesomeIcon icon={['fab', 'youtube']} style={{color: '#ff0000'}} />&nbsp;YouTube Channel</Link>.
            </Fragment>,
        fr: <Fragment>
              Je suis très passionné par les langues, et je suis très fier d'avoir appris seul le français à un niveau avancé, et d'avoir déménagé en France. Par ailleurs j'ai l'impression de faire partie
              d'une petite sousculture des internationaux à Montpellier, France; ce qui est quelque chose qui ne serait pas possible sans l'introduction d'une langue étrangère dans ma vie.
              De plus, j'enseigne le français de tous les jours et comment apprendre une langue sur ma <Link href={youtube} target="_blank" color="secondary" className="link-with-icon"><FontAwesomeIcon icon={['fab', 'youtube']} style={{color: '#ff0000'}} />&nbsp;chaîne YouTube</Link>.
            </Fragment>
      },
      subheading2: {
        en: <Fragment>Location-independance</Fragment>,
        fr: <Fragment>Style de vie</Fragment>
      },
      paragraph2: {
        en: <Fragment>
              The freedom to choose where I base myself, for days, weeks, months, or years should be independent of where my employer is located. Having a work/life balance is incredibly important for health
              as well as productivity. Being able to go to a co-living space for a few weeks at a time and gain a fresh perspective, whilst working on the go is ideal.
            </Fragment>,
        fr: <Fragment>
              Choisir où je vis, sur le court- et long-terme, devrait être un choix indépendant d'où se situe mon employeur. En ayant l'équilibre vie-travail est très important pour la santé ainsi que
              pour sa productivité. Pouvoir aller se loger dans un espace de co-living pendant quelques semaines est quelque chose que je fais une ou deux fois par an.
            </Fragment>
      },
      subheading3: {
        en: <Fragment>Experienced traveller</Fragment>,
        fr: <Fragment>Voyageur expérimenté</Fragment>
      },
      paragraph3: {
        en: <Fragment>
              I've visited 25 countries - backpacking through most of them - and lived in 3, so I have a real appreciation and respect for different cultures. I take the time to think about why someone
              may behave a certain way that's different to me, and this is down to travel. In the past, it was the destinations that appealed to me, and the landscapes of said destinations. As I've grown, I've
              learned to value experiences with other people more and more.
            </Fragment>,
        fr: <Fragment>
              J'ai visité 25 pays - en mode backpacker - et j'ai veçu au Royaume-Uni, en Australie, et en France. Donc, j'apprécie et respecte des cultures différentes. Je prends le temps de réfléchir à pourquoi
              on se comporterait d'une certaine manière, et c'est grâce au voyage. Dans le passé, je voyageais pour les déstinations, et les paysages. Au fils du temps, j'apprends à apprécier les expériences
              avec les autres de plus en plus.
            </Fragment>
      },
      subheading4: {
        en: <Fragment>I love sport</Fragment>,
        fr: <Fragment>J'adore le sport</Fragment>
      },
      paragraph4: {
        en: <Fragment>
              I'm a big fan of football (soccer) and support Arsenal, and regularly unwind with friends over a beer whilst watching the ups and downs of my club. I play badminton and have done since I was young,
              and most recently I've got into lifting weights at the gym. It's a great way to switch off and turn my focus onto the physical side of personal development.
            </Fragment>,
        fr: <Fragment>
              Je suis un grand fan de football, et d'Arsenal en particulier. Je me pose régulièrement avec des amis autour d'une bière en regardant les matchs. Je joue au badminton depuis mon adolescence,
              récemment je me suis mis à la musculation dans la salle de sport. C'est un bon moyen de se changer les idées et de me permettre de me reconcentrer sur le développment personnel.
            </Fragment>
      },
      subheading5: {
        en: <Fragment>Speaking of personal development&hellip;</Fragment>,
        fr: <Fragment>Sur le thème du développment personnel&hellip;</Fragment>
      },
      paragraph5: {
        en: <Fragment>
              I'm incredibly self-aware and try to always have purpose in what I do, both professionally and in my spare time. Filling one's time with things they enjoy is essential so self-awareness and mindfulness
              are great tools to be able to reach the goal of having a fulfilling life.
            </Fragment>,
        fr: <Fragment>
              Je suis très conscient de qui je suis, et je tâche toujours d'avoir une raison pour ce que je fais, autant professionnellement que personnellement. Remplir son temps avec des choses qui lui plaîsent est
              primordial, donc la pleine conscience est un outil sans prix pour atteindre son but de vivre une vie épanouissante.
            </Fragment>
      }
    }
  },
  work: {
    topHeading: {
      en: 'Work',
      fr: 'Œuvres'
    },
    subHeading1: {
      en: 'Some examples of my skills.',
      fr: 'Quelques exemples de mes créations.'
    },
    subHeading2: {
      en: 'Much more of my work is protected by contract law.',
      fr: 'Beaucoup d\'autres de mes créations sont protégées par contrat.'
    }
  }
}

export default l10n;

