import React, { Fragment } from 'react';
import { Typography, List, ListItem, ListItemIcon, ListItemText, Chip, Avatar, Tooltip, Button, GridList, GridListTile } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import l10n from './l10n';
import proPhoto from '../static/images/pro-photo.jpeg';
import personalPhoto from '../static/images/personal-photo.jpg';

const aboutPagePro = (lang) => {
  const keys = l10n.about.pro;

  return (
    <div>
      <Typography id="" variant="h3">{keys.topHeading[lang]}</Typography>
      <GridList cols={2} className="profile-summary-columns">
        <GridListTile rows={1.4} className="about-tile photo-tile">
          <img src={proPhoto} />
        </GridListTile>
        <GridListTile rows={1.4} className="about-tile">
          <Typography component="div" gutterBottom>
            <Tooltip placement="bottom-start" title={keys.tldr.label[lang]}>
              <Typography inline variant="h4">TLDR;</Typography>
            </Tooltip>
          </Typography>
          <Typography component="div" paragraph>
            <List dense={false} className="icon-list">
              {keys.tldr.items.map(item => (
                <ListItem key={item.id}>
                  <ListItemIcon className="icon-container"><FontAwesomeIcon icon={item.icon} /></ListItemIcon>
                  <ListItemText primary={item.text[lang]}/>
                </ListItem>
              ))}
            </List>
          </Typography>
        </GridListTile>
      </GridList>
      <Typography component="p" paragraph>{keys.paragraph1[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph2[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading1[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph3[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph4[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading2[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph5[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading3[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph6[lang]}</Typography>
      <Typography paragraph component="div" className="chips-list">
        {keys.chips1.map(chip => (
          <Chip key={chip.id} label={chip[lang]} color={chip.important ? 'secondary' : 'default'} variant="outlined" avatar={chip.icon && <Avatar><FontAwesomeIcon icon={chip.icon} /></Avatar>} />
        ))}
      </Typography>
      <Typography component="p" paragraph>{keys.paragraph7[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph8[lang]}</Typography>
      <Typography paragraph component="div" className="chips-list">
        {keys.chips1.map(chip => (
          <Chip key={chip.id} label={chip[lang]} color={chip.important ? 'secondary' : 'default'} variant="outlined" avatar={chip.icon && <Avatar><FontAwesomeIcon icon={chip.icon} /></Avatar>} />
        ))}
      </Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading4[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph9[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph10[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading5[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph11[lang]}</Typography>
    </div>
  );
}

const aboutPagePersonal = (lang) => {
  const keys = l10n.about.personal;

  return (
    <div>
      <Typography id="" variant="h3">{keys.topHeading[lang]}</Typography>
      <GridList cols={2} className="profile-summary-columns">
        <GridListTile rows={1.4} className="about-tile photo-tile">
          <img src={personalPhoto} />
        </GridListTile>
        <GridListTile rows={1.7} className="about-tile">
          <Typography component="div" gutterBottom>
            <Tooltip placement="bottom-start" title={keys.tldr.label[lang]}>
              <Typography inline variant="h4">TLDR;</Typography>
            </Tooltip>
          </Typography>
          <Typography component="div" paragraph>
            <List dense={false} className="icon-list">
              {keys.tldr.items.map(item => (
                <ListItem key={item.id}>
                  <ListItemIcon className="icon-container"><FontAwesomeIcon icon={item.icon} /></ListItemIcon>
                  <ListItemText primary={item.text[lang]}/>
                </ListItem>
              ))}
            </List>
          </Typography>
        </GridListTile>
      </GridList>
      <Typography variant="h4" gutterBottom>{keys.subheading1[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph1[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading2[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph2[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading3[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph3[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading4[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph4[lang]}</Typography>
      <Typography variant="h4" gutterBottom>{keys.subheading5[lang]}</Typography>
      <Typography component="p" paragraph>{keys.paragraph5[lang]}</Typography>
    </div>
  );
}

const aboutPage = (tab, lang) => {
  return (tab === 'pro' ? aboutPagePro(lang) : aboutPagePersonal(lang));
}

export default aboutPage;